<template>
  <div class="text-center mb-12 mt-12">
    <h2 class="relative z-10">
      {{ title }}
    </h2>

    <p class="relative z-10">
      {{ info }}
    </p>

    <div
      v-if="items"
      class="flex justify-center flex-wrap"
    >
      <div
        v-for="(employee, index) in items"
        :key="employee.id"
        class="text-center block w-full sm:w-1/3 px-2 pb-4"
      >
        <div v-if="images" class="mb-3">
          <img
            v-if="employee.images"
            :src="employee.images.medium"
            :alt="employee.name"
            class="mx-auto w-52 h-52 object-cover rounded"
          >

          <div v-else class="mx-auto w-52 h-52 bg-action text-action rounded flex items-center justify-center">
            <p class="text-3xl font-bold">
              {{
                employee.displayName
                  .charAt(0)
                  .toUpperCase()
              }}
            </p>
          </div>
        </div>

        <div class="relative">
          <h5 class="font-semibold">
            {{ employee.displayName }}
          </h5>

          <p class="text-sm opacity-80 break-words">
            {{ employee.bio?.length > 200 && bioShowHandler[index] === 'collapsed' ? `${employee.bio?.slice(0, 200).trim()}...` : employee.bio }}
          </p>

          <a v-if="employee.bio?.length > 200" class="text-primary text-sm absolute bottom-0 right-0 cursor-pointer" @click="handleExpand(index)">
            {{ bioShowHandler[index] === 'collapsed' ? 'Read more' : 'Read less' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeesBlock',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    info: {
      type: String,
      default: 'Info'
    },
    employees: {
      type: [Array, String],
      default: () => []
    },
    images: {
      type: [Boolean, String],
      default: true
    }
  },
  data () {
    return {
      items: [],
      bioShowHandler: []
    }
  },
  watch: {
    items () {
      this.bioShowHandler = this.items.map(item => 'collapsed')
    }
  },
  mounted () {
    this.fetchEmployees()
  },
  methods: {
    async fetchEmployees () {
      try {
        let employees = this.employees

        if (employees && employees !== 'undefined' && typeof employees === 'string') {
          employees = employees.split(',')
        }

        const response = await this.$api.location.employees(employees)

        const items = response.employees

        if (items.length && employees.length) {
          items.sort((a, b) => {
            return employees.indexOf(a.id.toString()) - employees.indexOf(b.id.toString())
          })
        }

        this.items = items
      } catch (e) {
        console.log(e)
      }
    },
    handleExpand (index) {
      this.$set(this.bioShowHandler, index, this.bioShowHandler[index] === 'collapsed' ? 'expanded' : 'collapsed')
    }
  }
}
</script>
