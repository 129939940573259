// Country flags from: https://cdn.countryflags.com/thumbs/ireland/flag-400.png

export default [
  {
    code: 'en',
    file: 'en-GB.js',
    name: 'English (GB)',
    icon: '🇬🇧',
    date: 'enGB'
  },
  {
    code: 'en-IE',
    file: 'en-GB.js',
    name: 'English (IE)',
    icon: '🇮🇪'
  },
  {
    code: 'en-US',
    file: 'en-US.js',
    name: 'English (US)',
    icon: '🇺🇸'
  },
  {
    code: 'en-AU',
    file: 'en-AU.js',
    name: 'English (AU)',
    icon: '🇦🇺'
  },
  {
    code: 'es',
    file: 'es.js',
    name: 'Español',
    icon: '🇪🇸',
    date: 'es'
  },
  {
    code: 'et',
    file: 'et-EE.js',
    name: 'Eesti keel (Eesti)',
    icon: '🇪🇪'
  },
  {
    code: 'nl',
    iso: 'nl',
    file: 'nl.js',
    name: 'Nederlands',
    icon: '🇳🇱'
  },
  {
    code: 'fr-CA',
    iso: 'fr-CA',
    file: 'fr-CA.js',
    name: 'Français (Canadian)',
    icon: '🇨🇦'
  },
  {
    code: 'it',
    iso: 'it',
    file: 'it.js',
    name: 'Italiano',
    icon: '🇮🇹'
  },
  {
    code: 'sl',
    iso: 'sl',
    file: 'sl.js',
    name: 'Slovenščina',
    icon: '🇸🇮'
  },
  {
    code: 'de',
    iso: 'de',
    file: 'de.js',
    name: 'Deutsch',
    icon: '🇩🇪'
  },
  {
    code: 'ja',
    iso: 'ja',
    file: 'ja.js',
    intl: 'ja.js',
    name: '日本語',
    icon: '🇯🇵',
    date: 'ja'
  },
  {
    code: 'fa',
    iso: 'fa',
    file: 'fa.js',
    name: 'فارسی',
    icon: '🇮🇷',
    dir: 'rtl'
  },
  {
    code: 'pt',
    iso: 'pt',
    file: 'pt.js',
    name: 'Português',
    icon: '🇵🇹'
  },
  {
    code: 'hr',
    iso: 'hr',
    file: 'hr.js',
    name: 'Hrvatski',
    icon: '🇭🇷'
  },
  {
    code: 'hu',
    iso: 'hu',
    file: 'hu.js',
    name: 'magyar',
    icon: '🇭🇺',
    date: 'hu'
  }
]
