<template>
  <div class="text-center mb-12 mt-12">
    <h2 class="relative z-10">{{ title }}</h2>

    <p class="relative z-10">{{ info }}</p>

    <div
      v-if="items"
      class="flex justify-center flex-wrap"
    >
      <div v-for="service in items" class="w-full sm:w-1/3 px-2 pb-4 ">
        <div
          :key="service.id"
          class="text-left hover:scale-105 cursor-pointer transition-all bg-primary rounded overflow-hidden flex flex-col border h-full"
        >
          <div v-if="images != false" class="p-3 rounded overflow-hidden">
            <img
              v-if="service.images" :src="service.images.medium" :alt="service.name"
              class="mx-auto h-40 w-full object-cover rounded"
            />

            <div v-else class="mx-auto w-full h-40 bg-action text-action flex items-center justify-center rounded">
              <p class="text-3xl font-bold">
                {{
                  service.title
                    .charAt(0)
                    .toUpperCase()
                }}
              </p>
            </div>
          </div>

          <div class="p-3 flex flex-col justify-between flex-grow">
            <div class="">
              <h5 class="font-semibold text-lg">{{ service.title }}</h5>

              <div class="flex items-center space-x-2 mb-2">
                <b-chip>
                  <template v-if="service.priceFrom">
                    From
                  </template>
                  <span v-else>
                    {{ service.priceLabel }}
                  </span>
                </b-chip>

                <b-chip>
                  {{ service.duration }} min
                </b-chip>
              </div>

              <p v-if="service.information" class="text-sm opacity-80 overflow-hidden line-clamp-2 mb-2">{{ service.information }}</p>
            </div>

            <div
              class="flex items-center space-x-2 mt-4"
            >
              <b-button
                variant="action"
                flat
                :to="`/service/${ service.id }`"
                class="w-1/2 flex-grow"
              >
                Learn more
              </b-button>

              <b-button
                v-if="service.allowInternet"
                variant="action"
                :to="`/book-now/service/${ service.id }`"
                class="w-1/2"
              >
                {{ $t('general.bookNow') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BookingStage, createBookingRoute } from '@/components/booking/elements/Detail.vue'

export default {
  name: 'ServicesBlock',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    info: {
      type: String,
      default: 'Info'
    },
    services: {
      type: [Array, String],
      default: []
    },
    images: {
      type: [Boolean, String],
      default: true
    },
  },
  data () {
    return {
      items: []
    }
  },
  mounted() {
    this.fetchServices()
    this.$store.dispatch('location/getServices')
  },
  methods: {
    async fetchServices () {
      try {
        let services = this.services

        if (services && services !== 'undefined' && typeof services === 'string') {
          services = services.split(',')
        }

        const response = await this.$api.location.services(services)

        const items = response.services

        if (items.length && services.length) {
          items.sort((a, b) => {
            return services.indexOf(a.id.toString()) - services.indexOf(b.id.toString())
          })
        }

        this.items = items
      } catch (e) {
        console.log(e)
      }
    },
    createBookingURL(service) {
      return `/book-now/availability?services=${Buffer.to(service.id, 'base64')}`
    },
    handleClickService (service) {
      this.$store.dispatch('booking/clearServiceSelections')
      this.$store.dispatch('booking/setServiceSelected', { service, employee: null, selected: true, toBack: true })

      const bookingUrlQuery = this.$store.getters['booking/urlQuery']
      const route = createBookingRoute(bookingUrlQuery, BookingStage.SERVICES)

      this.$router.push(route)
    }
  }
}
</script>
