<template>
  <nav
    v-if="count > 1"
    class="sm:hidden grid bg-primary shadow-lg px-4 pt-4 pb-6 text-action z-20 w-full"
    :class="`grid-cols-${count}`"
  >
    <template v-if="customMenu">
      <template v-for="item in customMenu">
        <template v-if="item.type === 'group' && item.children && item.children.length">
          <mobile-group :item="item" />
        </template>

        <template v-else-if="item.type === 'tab' || item.type === 'custom'">
          <mobile-item :item="item" :key="`${item.id}-${item.type}`" />
        </template>
      </template>
    </template>

    <!-- Has Default (Auto) Menu -->
    <template v-else>
      <template v-for="tab in enabledTabs">
        <mobile-item
          v-if="$site.tabs.book"
          :key="tab"
          :item="{ id: tab, name: $site.tabs.getName(tab, tab) }"
        />
      </template>
    </template>
  </nav>
</template>

<script>
import MobileItem from './MobileItem'
import MobileGroup from './MobileGroup'

export default {
  components: {
    MobileItem,
    MobileGroup
  },
  data () {
    return {
      tabs: [
        'book',
        'timetable',
        'lastMinute',
        'giftCard',
        'pass',
        'gallery',
        'review'
      ]
    }
  },
  computed: {
    customMenu () {
      if (this.$site.menu?.primary) {
        return this.$site.menu?.primary || []
      }

      return false
    },
    layout () {
      return this.$store.getters.layout
    },
    isApp () {
      return this.layout === 'app'
    },
    enabledTabs () {
      const tabs = this.tabs
        .filter(tab => this.$site.tabs[tab])

      tabs.length = Math.min(tabs.length, 5)

      return tabs
    },
    count () {
      if (this.customMenu) {
        return this.customMenu.length
      }

      return this.enabledTabs.length
    }
  }
}
</script>
